import React, { ReactNode } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { IHTMLBaseProperties } from '@brainstud/ui/Types/ElementTypes';
import { ChevronRight } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './Steps.module.css';

export type StepsProps = {
  children: ReactNode | ReactNode[];
  currentStep?: number;
  className?: string;
};

export interface StepsSubComponentProps extends IHTMLBaseProperties {
  children: ReactNode;
  active?: boolean;
  completed?: boolean;
  onClick?: () => void;
  to?: string;
}

const cx = classNames.bind(styles);

/**
 * ProgressStepper
 *
 * With this component you can see the current step/page a users is on.
 * Including an active styling, also the steps can be made quiet.
 *
 * The currentStep should be provided, to indicate which step is completed,
 * you can either set active yourself of let the component do this for you (uses currentStep)
 *
 * Next to only label you have the ability to add navigation functionality using `to` or `onClick`,
 *
 */

export const Steps = ({ children, currentStep, className }: StepsProps) => {
  const childSteps = React.Children.map(children, (child, index) => {
    // Ensure child is a valid React element
    if (React.isValidElement<StepsSubComponentProps>(child)) {
      return React.cloneElement(child, {
        active: child.props.active || index + 1 === currentStep,
        completed: (currentStep && index + 1 <= currentStep) || false,
      });
    }
    return child;
  });

  return <div className={cx(styles.stepper, className)}>{childSteps}</div>;
};

Steps.Step = ({
  className,
  style,
  active,
  completed,
  onClick,
  to,
  children,
}: StepsSubComponentProps) => (
  <div className={cx(styles.stepWrapper)}>
    <div
      className={cx(styles.step, className, { active, completed })}
      style={style}
    >
      {onClick || to ? (
        <Button
          to={to}
          quiet
          small
          onClick={onClick}
          disabled={!completed || active}
        >
          {children}
        </Button>
      ) : (
        <strong>{children}</strong>
      )}
    </div>
    <ChevronRight fontSize="large" className={cx(styles.chevron)} />
  </div>
);
