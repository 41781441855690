import React from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Panel } from '@brainstud/ui/Static/Panel';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { FlexContainer } from 'Components/FlexContainer';
import { Link } from 'Components/Link';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useTranslator } from 'Providers/Translator';
import styles from './AccountTabs.module.css';

export type AccountTabsProps = {
  children: React.ReactNode;
};

const cx = classNames.bind(styles);

const AccountTabs = ({ children }: AccountTabsProps) => {
  const [t] = useTranslator();
  const [me] = useMe();
  const isNotCoach = !me?.account?.()?.roles.includes('coach');

  useBreadcrumbs({
    account: { href: 'details' },
    options: null,
    subscriptions: { href: 'options' },
  });

  return (
    <Container className={cx(styles.base)}>
      <FlexContainer row justifyContent="space-between" alignItems="center">
        <h1>{t('account.title')}</h1>
        <Button outline href="/account/profile">
          {t('account.my_profile')}
        </Button>
      </FlexContainer>
      <p>{t('account.description')}</p>

      <FlexContainer>
        <Panel className={cx(styles.menu)}>
          <nav>
            <Link href="/account/details">
              <span>{t('account.tabs.information')}</span>
            </Link>
            {isNotCoach && (
              <Link href="/account/primary-coach">
                <span>{t('account.tabs.primary_coach')}</span>
              </Link>
            )}
            <Link href="/account/orders">
              <span>{t('account.tabs.purchases')}</span>
            </Link>
            <Link href="/account/notifications">
              <span>{t('account.tabs.notifications')}</span>
            </Link>
            <Link href="/account/security">
              <span>{t('account.tabs.security')}</span>
            </Link>
            <Link href="/account/profile_permissions">
              <span>{t('account.tabs.privacy')}</span>
            </Link>
          </nav>
        </Panel>

        {children}
      </FlexContainer>
    </Container>
  );
};

export default AccountTabs;
