import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  getBrowserCookie,
  removeBrowserCookie,
  setBrowserCookie,
} from 'Utils/cookies';

export const RETURN_TO_COOKIE_VALUE = 'return-to';

/**
 * useReturnToValue.
 *
 * Sets the return to value in session storage when set in query string and provides it when requested.
 */
export function useReturnToValue() {
  const router = useRouter();
  const { query } = router;
  const [returnTo, setReturnTo] = useState<string>('/');
  const queryReturnTo = !Array.isArray(query.return) ? query.return : '/';

  useEffect(() => {
    if (typeof queryReturnTo === 'string') {
      setBrowserCookie(RETURN_TO_COOKIE_VALUE, queryReturnTo);
    }
  }, [queryReturnTo]);

  useEffect(() => {
    setReturnTo(
      getBrowserCookie(RETURN_TO_COOKIE_VALUE) || queryReturnTo || '/'
    );
  }, [queryReturnTo]);

  const clearReturnTo = useCallback(() => {
    removeBrowserCookie(RETURN_TO_COOKIE_VALUE);
  }, []);

  return [returnTo, clearReturnTo] as const;
}
