import React from 'react';
import { useOrder } from '@brainstud/academy-api/Hooks/useOrders';
import { ChevronLeft } from '@mui/icons-material';
import { Container } from 'Components/Container';
import { Link } from 'Components/Link';
import { Steps } from 'Components/Steps';
import { useBreakpoints } from 'Hooks/useBreakpoints';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useHeadProvider } from 'Providers/HeadProvider/useHeadProvider';
import { useTranslator } from 'Providers/Translator';
import styles from './CheckoutLayout.module.css';

type Props = {
  children?: React.ReactNode;
};

const CheckoutLayout = ({ children }: Props) => {
  useHeadProvider('titles.account.checkout.main');
  const router = useRouter();
  const { orderId } = router.query as { orderId: string };
  const [{ data: order }] = useOrder({ order: orderId });
  const [t] = useTranslator();
  const { smDown } = useBreakpoints();

  useBreadcrumbs(
    {
      [orderId]: { text: t('components.breadcrumbs.purchase'), href: null },
    },
    [orderId]
  );

  const hasPreRegistration = !!order?.preRegistrationRequired;
  const steps = [
    { label: t('checkout.breadcrumbs.order') },
    ...(hasPreRegistration
      ? [{ label: t('checkout.breadcrumbs.pre_registration') }]
      : []),
    { label: t('checkout.breadcrumbs.payment_methods') },
    { label: t('checkout.breadcrumbs.payment') },
  ];

  const currentStep =
    {
      '/account/subscriptions/options': 1,
      '/account/orders/[orderId]/user-details': 2,
      '/account/orders/[orderId]/methods': hasPreRegistration ? 3 : 2,
      '/account/orders/[orderId]/confirmation': hasPreRegistration ? 4 : 3,
    }[router.pathname] || 3;

  return (
    <div className={styles.checkoutLayout}>
      <Container>
        {smDown && (
          <Link href="/account/orders" className={styles.cancelLink}>
            <ChevronLeft />
            <span>{t('checkout.cancel_order')}</span>
          </Link>
        )}
        <Steps currentStep={currentStep}>
          {steps.map((step) => (
            <Steps.Step key={step.label}>{step.label}</Steps.Step>
          ))}
        </Steps>
        <h1>{t('checkout.title')}</h1>
        {children}
      </Container>
    </div>
  );
};

export default CheckoutLayout;
