import { Config, sanitize } from 'dompurify';

const Presets = {
  plainText: {
    ALLOWED_TAGS: [],
  },
  inlineOnly: {
    ALLOWED_TAGS: [
      'span',
      'b',
      'strong',
      'u',
      'a',
      'dfn',
      'q',
      'time',
      'abbr',
      'em',
      'samp',
      'tt',
      'acronym',
      'i',
      'var',
      'small',
      'big',
      'sub',
      'sup',
      'code',
      'cite',
    ],
    ADD_ATTR: ['target'],
  },
  unstyle: {
    FORBID_ATTR: ['style'],
  },
  permissive: {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'style'],
    FORBID_TAG: ['style'],
  },
};

/**
 * Sanitize input before using it in, for example, a dangerouslySetInnerHtml prop.
 * @param value The value to sanitize
 * @param preset A preset to use, such as 'inlineOnly' or remove styling with 'unstyle'.
 * @param settings Settings that are passed to [DOMPurify](https://github.com/cure53/DOMPurify/tree/main/demos#what-is-this)
 */
export function sanitizer(
  value: string = '',
  preset?: keyof typeof Presets,
  settings?: Config
) {
  const config = preset
    ? {
        ...Presets[preset],
        ...(settings || {}),
      }
    : settings || {};

  return sanitize(value, {
    ...config,
    RETURN_DOM_FRAGMENT: false,
    RETURN_DOM: false,
  });
}
