import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Order,
  OrderCreate,
  OrderFilters,
  OrderIncludes,
  OrderSort,
  OrderUpdate,
} from '../Types/Resources/Order';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IOrderProps
  extends HookParams<typeof OrderIncludes, OrderFilters, typeof OrderSort> {
  order?: UUID;
}

export function useOrder(
  { order, ...queryParameters }: IOrderProps = {},
  settings?: IUseQueryOptions<DataDocument<Order>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(order || defaultOrder),
      baseName: 'v1.orders',
      baseUri: '/v1/orders',
      invalidate: ['order', 'orders', 'v1.accounts.orders'],
      uri: `/${order}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Order, OrderCreate, OrderUpdate>(request, settings);
}

export function useOrders(
  { ...queryParameters }: Omit<IOrderProps, 'order'> = {},
  settings?: IUseQueryOptions<DataDocument<Order[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.orders',
      baseUri: '/v1/orders',
      invalidate: ['order', 'orders', 'v1.accounts.orders'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Order, OrderCreate>(request, settings);
}
