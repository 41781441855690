import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * useBreakpoints
 *
 * A hook to give easy (and consistant) access to several breakpoints that can
 * be use to hide/show components.
 */
export const useBreakpoints = () => {
  const xsUp = true;
  const smUp = useMediaQuery('(min-width:600px)');
  const mdUp = useMediaQuery('(min-width:960px)');
  const lgUp = useMediaQuery('(min-width:1440x)');
  const xlUp = useMediaQuery('(min-width:1920px)');

  const xsDown = useMediaQuery('(max-width:599px)');
  const smDown = useMediaQuery('(max-width:959px)');
  const mdDown = useMediaQuery('(max-width:1024px)');
  const lgDown = useMediaQuery('(max-width:1921x)');
  const xlDown = true;

  return {
    xsUp,
    smUp,
    mdUp,
    lgUp,
    xlUp,
    xsDown,
    smDown,
    mdDown,
    lgDown,
    xlDown,
  };
};
